import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";

import { fbconfig } from "../config";
import { USER_COLLECTION } from "./collections";

const rrfConfig = {
  userProfile: USER_COLLECTION,
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true, // Get custom claims along with the profile
};

firebase.initializeApp(fbconfig);

const auth = firebase.auth();
const storage = firebase.storage();
const firestore = firebase.firestore();

export { firebase, fbconfig, rrfConfig, auth, storage, firestore, USER_COLLECTION };
