import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Modal, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FormField from "../../../components/Custom/FormField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function AddModal({
  open,
  handleClose,
  onCreate,
  model,
  onChange,
  residential,
  apartments,
}) {
  let isDisabled =React.useMemo(()=>{
    return !(model.firstName && model.lastName && model.apartment && model.phoneNumber && model.userName && model.password && model.residential && model.apartment)},[model])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          AGREGAR PROPIETARIO
        </Typography>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.firstName}
            name="firstName"
            onChange={onChange}
            label="Nombres"
            placeholder="Juan"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
              value={model.lastName}
              name="lastName"
              onChange={onChange}
              fontWeight="bold"
              label="Apellidos"
              placeholder="Duarte"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.phoneNumber}
            name="phoneNumber"
            onChange={onChange}
            fontWeight="bold"
            label="Numero Telefonico"
            placeholder="809-223-2709"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.mail}
            name="mail"
            onChange={onChange}
            fontWeight="bold"
            label="Correo"
            placeholder="hola@hola.com"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
              value={model.userName}
              name="userName"
              onChange={onChange}
              fontWeight="bold"
              label="Usuario"
              placeholder="Duarte"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
              value={model.password}
              name="password"
              onChange={onChange}
              fontWeight="bold"
              label="Contraseña (6 Caracteres)"
              placeholder="juan01"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.comment}
            name="comment"
            onChange={onChange}
            fontWeight="bold"
            label="Comentario"
            placeholder="Nuevo Propietario"
          />
        </Grid>
         <br />
        <Grid item xs={12}>
          <Autocomplete
            value={model.residential}
            onChange={(e, value) =>
              onChange(e, { id: value.id, label: value.label, autocomplete: "residential" })
            }
            disableClearable
            options={residential}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
              );
            }}
            renderInput={(params) => (
              <FormField {...params} label="Residencial" InputLabelProps={{ shrink: true }} />
            )}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <Autocomplete
            value={model.apartment}
            onChange={(e, value) =>
              onChange(e, { id: value.id, label: value.label, autocomplete: "apartment" })
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={apartments}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
              return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
              );
            }}
            renderInput={(params) => (
              <FormField {...params} label="Apartamento" InputLabelProps={{ shrink: true }} />
            )}
          />
        </Grid>
        <br />
        <MDButton disabled={isDisabled} style={{ marginRight: 5 }} color="success" onClick={onCreate}>
          Crear
        </MDButton>
        <MDButton onClick={handleClose} color="error">
          Cancelar
        </MDButton>
      </MDBox>
    </Modal>
  );
}
