import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ChangePassword from "./components/ChangePassword";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import {useFirebase} from "react-redux-firebase";
import {AlertModel} from "../../model";
import fb from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import CAlertModal from "components/Custom/CAlertModal";
import LoadingModal from "components/Custom/Loading";
import {useSelector} from "react-redux";

function Profile() {
    const firebase = useFirebase();
    const model = useSelector((state)=>state.userInfo);
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [cAlert, setCAlert] = React.useState(new AlertModel());
    const [loading, setLoading] = React.useState(false);


    const logout = () => {
        firebase.auth().signOut().catch(e => {
            console.log(e)
        })
    }


    const reauthenticate = () => {
        let user = fb.auth().currentUser;
        let cred = fb.auth.EmailAuthProvider.credential(user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
    };

    const onChangePassword = () => {
        setLoading(true)
        reauthenticate()
            .then(() => {
                const user = fb.auth().currentUser;
                user
                    .updatePassword(password)
                    .then(() => {
                        setCAlert({ open: true, type: "success", ms: "Contraseña Actualizada" });
                        setLoading(false)
                    })
                    .catch(() => {
                        setCAlert({ open: true, type: "error", ms: "Error actualizando contraseña" });
                        setLoading(false)
                    })
            })
            .catch(() => {
                setCAlert({ open: true, type: "error", ms: "Error actualizando contraseña" });
                setLoading(false)
            });
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <MDBox mb={3}>
                            <Grid container spacing={3}>

                                <Grid item xs={12}>
                                    <BasicInfo model={model}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ChangePassword
                                        onChangePassword={onChangePassword}
                                        currentPassword={currentPassword}
                                        password={password}
                                        confirmPassword={confirmPassword}
                                        setCurrentPassword={setCurrentPassword}
                                        setPassword={setPassword}
                                        setConfirmPassword={setConfirmPassword}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Header logout={logout} model={model}/>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <CAlertModal alert={cAlert} close={setCAlert}/>
            <LoadingModal loading={loading}/>
        </DashboardLayout>
    );
}

export default Profile;
