import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Modal, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FormField from "../../../components/Custom/FormField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function EditModal({ open, handleClose, onCreate, model, onChange, residential }) {

  let isDisabled =React.useMemo(()=>{
    return !(model.residential && model.employeesName && model.employeesPosition && model.employeesSalary && model.employeesTel && model.employeesPay)})

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          ACTUALIZAR DATOS DEL EMPLEADO
        </Typography>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.employeesName}
            name="employeesName"
            onChange={onChange}
            label="Nombre Del Empleado"
            placeholder="Juan Polanco"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.employeesTel}
            name="employeesTel"
            onChange={onChange}
            label="Contacto"
            placeholder="809-223-1133"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.employeesPosition}
            name="employeesPosition"
            onChange={onChange}
            label="Puesto"
            placeholder="Supervisor"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.employeesSalary}
            name="employeesSalary"
            onChange={onChange}
            label="Salario"
            placeholder="14,900"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.employeesPay}
            name="employeesPay"
            onChange={onChange}
            label="Día de Pago"
            placeholder="5"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <Autocomplete
            value={model.residential}
            onChange={(e, value) =>
              onChange(e, { id: value.id, label: value.label, autocomplete: "residential" })
            }
            options={residential}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <FormField {...params} label="Residencial" InputLabelProps={{ shrink: true }} />
            )}
          />
        </Grid>
        <br />
        <MDButton disabled={isDisabled} style={{ marginRight: 5 }} color="success" onClick={onCreate}>
          Actulizar
        </MDButton>
        <MDButton onClick={handleClose} color="error">
          Cancelar
        </MDButton>
      </MDBox>
    </Modal>
  );
}
