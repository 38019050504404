import React from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export default function MonthsToPay({handle, monthsList}) {
    return (
        <>
            <h5 style={{marginBottom:5}}>Mes/es a pagar</h5>
            <Stack direction="row" style={{flexWrap:'wrap'}} spacing={2}>
                {monthsList.map(m=>{return  <Chip color={m.selected?'success':'default'} style={{margin:8}} label={m.label} key={m.id} onClick={()=>handle(m)}/> })}
            </Stack>
        </>

    )
}
