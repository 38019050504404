import {SESSION, GET_SUMMARY, GET_USERINFO} from "../actions";
import {UserModel} from "../../model";

export const INITIAL_STATE = {
    session: null,
    summary: {
        residential: 0,
        apartment: 0,
        users: 0,
    },
    userInfo: new UserModel(),
};

export default function Reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION:
            return {...state, session: action.payload};
        case GET_SUMMARY:
            return {...state, summary: action.payload};
        case GET_USERINFO:
            return { ...state, userInfo: action.payload };
        default:
            return state;
    }
}
