const fbconfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const baseURL = process.env.REACT_APP_FIREBASE_API_URL
const BUSINESS_LOGO = process.env.REACT_APP_BUSINESS_LOGO
const BUSINESS_NAME = process.env.REACT_APP_BUSINESS_NAME
const BUSINESS_EMAIL = process.env.REACT_APP_BUSINESS_EMAIL
const BUSINESS_PHONE = process.env.REACT_APP_BUSINESS_PHONE
const BUSINESS_BG_COLOR = process.env.REACT_APP_BUSINESS_BG_COLOR

export {
    fbconfig, baseURL,
  BUSINESS_LOGO,
    BUSINESS_NAME,
    BUSINESS_EMAIL,
    BUSINESS_PHONE,
    BUSINESS_BG_COLOR
};

