import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {Modal, Typography} from "@mui/material";
import React from "react";
import DataTable from "../../../examples/Tables/DataTable";
import {modalStyle} from "../../../utilities/constants";

export default function RecordPaymentModal({
                                               open,
                                               handleClose,
                                               data = [],
                                           }) {


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MDBox sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                    Historico de ingresos
                </Typography>
                <br/>
                {data.length === 0 ? (
                    <MDBox mt={4}>
                     <h3 style={{padding:40}}>No tiene historico de ingresos</h3>
                    </MDBox>
                ) : (
                    <MDBox mt={4}>
                        <DataTable
                            canSearch
                            table={{
                                columns: [
                                    {Header: "fecha", accessor: "createdAt"},
                                    {Header: "residencial", accessor: "residential.label"},
                                    {Header: "apt.", accessor: "apartment.label"},
                                    {Header: "monto", accessor: "amount"},
                                    {Header: "via", accessor: "bank.label"},
                                    {Header: "comentario", accessor: "comment"},
                                ],
                                rows: data,
                            }}
                        />
                    </MDBox>
                )}

                <br/>
                <MDButton onClick={handleClose} color="error">
                    Cerrar
                </MDButton>
            </MDBox>
        </Modal>
    );
}
