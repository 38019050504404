import React from "react";
import { useFirebase } from "react-redux-firebase";
import AdminLayout from "./layouts/dashboards";
import AuthLayout from "./layouts/authentication";
import LoadingModal from "./components/Custom/Loading";
import {getSummary, getUserInfo} from "./redux/actions";
import {useDispatch} from "react-redux";

export default function MainApp() {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(false);

  firebase.auth().onAuthStateChanged((currentUser) => {
    if (currentUser) {
      try {
        localStorage.setItem("token", currentUser.uid);
        dispatch(getSummary())
        dispatch(getUserInfo())
        setLoading(false);
        setUser(true);
      } catch (e) {
        setLoading(false);
      }
    } else {
      localStorage.removeItem("token");
      setUser(false);
      setLoading(false);
    }
  });

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, []);

  if (loading) {
    return <LoadingModal loading={loading} />;
  }

  return <>{!user ? <AuthLayout /> : <AdminLayout />}</>;
}
