import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {paymentStatus} from "../../utilities/constants";


export default function Filters({residential, onChange, values, onClick}) {

    let disabled =React.useMemo(()=>{
        return !(values.residential.id.length>0 && values.paymentStatus.id.length>0 )
    },[values])

    return (
        <MDBox display="flex" alignItems="center" bgColor={'#fff'} p={2} mt={2} borderRadius={8}>
            <MDBox width="14rem" mr={2}>
                <Autocomplete
                    options={residential}
                    disableClearable
                    clearOnEscape
                    size="small"
                    renderInput={(params) => <MDInput {...params} placeholder={'Residencial'} />}
                    onChange={(e, value) =>
                        onChange(e, {id: value.id, label: value.label, autocomplete: "residential"})
                    }
                    getOptionLabel={(option) => option.label || ''}
                />
            </MDBox>
            <MDBox width="14rem" mr={2}>
                <Autocomplete
                    options={paymentStatus}
                    disableClearable
                    clearOnEscape
                    size="small"
                    renderInput={(params) => <MDInput {...params} placeholder={'Estado de pago'} />}
                    onChange={(e, value) =>
                        onChange(e, {id: value.id, label: value.label, autocomplete: "paymentStatus"})
                    }
                    getOptionLabel={(option) => option.label || ''}
                />
            </MDBox>

            <MDBox width="10rem">
                <MDButton disabled={disabled}  onClick={onClick} fullWidth color="secondary">
                    Filtrar
                </MDButton>
            </MDBox>

        </MDBox>
    )
}
