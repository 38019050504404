import React from "react";
import { useFirebase } from "react-redux-firebase";
import { TableActions } from "./components/Table.Actions";
import { AlertModel, BankModel } from "../../model";

const useData = () => {
  const firebase = useFirebase();
  const [model, setModel] = React.useState(new BankModel());
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [bank, setBank] = React.useState([]);

  React.useEffect(() => {
    const db = firebase.firestore();
    db.collection("bank")
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const list = [];
        docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          list.push(data);
        });
        setBank(list);
      });
  }, []);

  const actions = {
    onEdit: (data) => {
      setModel(data);
      setOpenEdit(true);
    },

    onDelete: (data) => {
      setModel(data);
      setOpenDelete(true);
    },
  };
  const onCreate = () => {
    setLoading(true);
    setOpen(false);
    const db = firebase.firestore();
    db.collection("bank")
      .add({
        createdBy: {
          id: firebase.auth().currentUser.uid,
          displayName: firebase.auth().currentUser.displayName,
        },
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        bank: model.bank,
        account: model.account,
        headline: model.headline,
        type: model.type,
      })
      .then(() => {
        setCAlert({ open: true, type: "success", ms: "Cuenta de Banco Creada Correctamente" });
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
        setModel(new BankModel());
      });
  };
  const onUpdate = () => {
    setLoading(true);
    setOpenEdit(false);
    const db = firebase.firestore();
    db.collection("bank")
      .doc(model.id)
      .update({
        updatedBy: {
          id: firebase.auth().currentUser.uid,
          displayName: firebase.auth().currentUser.displayName,
        },
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        bank: model.bank,
        account: model.account,
        headline: model.headline,
        type: model.type,
      })
      .then(() => {
        setCAlert({ open: true, type: "success", ms: "Cuenta de Banco Actualizada" });
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
        setModel(new BankModel());
      });
  };

  const onDelete = () => {
    setOpenDelete(false);
    const db = firebase.firestore();
    db.collection("bank")
      .doc(model.id)
      .delete()
      .then(() => {
        setCAlert({ open: true, type: "success", ms: "Cuenta de Banco Eliminada" });
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
        setModel(new BankModel());
      });
  };

  const onChange = (e, select) => {
    if (!select) {
      const { name, value } = e.target;
      setModel((prevState) => ({ ...prevState, [name]: value }));
    } else {
      const selected = { ...select };
      const name = selected.autocomplete;
      delete selected.autocomplete;
      setModel((prevState) => ({ ...prevState, [name]: selected }));
    }
  };

  const bankList = bank.map((data) => ({
    ...data,
    id: data.id,
    actions: <TableActions data={data} {...actions} />,
  }));

  return {
    bankList,
    model,
    setModel,
    openDelete,
    openEdit,
    open,
    setOpen,
    setOpenDelete,
    setOpenEdit,
    cAlert,
    setCAlert,
    loading,
    onChange,
    onCreate,
    onUpdate,
    onDelete,
  };
};

export default useData;
