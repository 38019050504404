import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Modal, Typography } from "@mui/material";
import React from "react";
import FormField from "../../../components/Custom/FormField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};
export default function EditModal({ open, handleClose, onCreate, model, onChange }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          ACTUALIZAR DATOS DE LA CUENTA
        </Typography>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.bank}
            name="bank"
            onChange={onChange}
            fontWeight="bold"
            label="Nombre Del Banco"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.account}
            name="account"
            onChange={onChange}
            fontWeight="bold"
            label="Numero De Cuenta"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.headline}
            name="headline"
            onChange={onChange}
            fontWeight="bold"
            label="Titular"
            placeholder="Manolo Troncoso"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.type}
            name="type"
            onChange={onChange}
            fontWeight="bold"
            label="Tipo de Cuenta"
            placeholder="Cuenta de Ahorro"
          />
        </Grid>
        <br />
        <MDButton style={{ marginRight: 5 }} color="success" onClick={onCreate}>
          Actulizar
        </MDButton>
        <MDButton onClick={handleClose} color="error">
          Cancelar
        </MDButton>
      </MDBox>
    </Modal>
  );
}
