import React from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import DefaultStatisticsCard from "../../examples/Cards/StatisticsCards/DefaultStatisticsCard";
import {useSelector} from "react-redux";

export default function Dashboard() {
    const summary = useSelector((state)=>state.summary);

    return <DashboardLayout>
        <DashboardNavbar/>
        <MDBox mt={4}>
            <MDBox mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <DefaultStatisticsCard
                            title="Residenciales"
                            count={summary.residential}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DefaultStatisticsCard
                            title="Apartamentos"
                            count={summary.apartment}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <DefaultStatisticsCard
                            title="Propietarios e inquilinos"
                            count={summary.users}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    </DashboardLayout>;
}
