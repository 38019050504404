import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
    Modal, Typography, FormGroup,
    FormControlLabel,
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FormField from "../../../components/Custom/FormField";
import {modalStyle} from "../../../utilities/constants";

export default function AddModal({
                                     open,
                                     handleClose,
                                     onCreate,
                                     model,
                                     onChange,
                                     residential,
                                 }) {
    let isDisabled = React.useMemo(() => {
        return !(model.apartment && model.residential)
    }, [model.apartment, model.residential])

    const [isDue, setIsDue] = React.useState(false)

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MDBox sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                    AGREGAR APARTAMENTO
                </Typography>
                <br/>
                <Grid item xs={12}>
                    <FormField
                        value={model.apartment}
                        name="apartment"
                        onChange={onChange}
                        label="Apartamento"
                        placeholder="A-1"
                    />
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <Autocomplete
                        onChange={(e, value) =>
                            onChange(e, {id: value.id, label: value.label, autocomplete: "residential"})
                        }
                        disableClearable
                        options={residential}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <FormField {...params} label="Residencial" InputLabelProps={{shrink: true}}/>
                        )}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Grid>
                <br/>

                <Grid item xs={12} style={{marginBottom: 15}}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={isDue} onClick={()=>setIsDue(!isDue)}/>} label="¿Existe deuda?"/>
                    </FormGroup>
                </Grid>

                {isDue && (
                    <>
                        <Grid item xs={12}>
                            <FormField
                                value={model.previousDueAmount}
                                type={'number'}
                                name="previousDueAmount"
                                onChange={onChange}
                                label="Monto de la deuda"
                                placeholder="500"
                            />
                        </Grid>
                        <br/>
                        <Grid item xs={12}>
                            <FormField
                                type={'date'}
                                value={model.previousDueLastPayment}
                                name="previousDueLastPayment"
                                onChange={onChange}
                                label="Fecha de ultimo pago"
                                placeholder="2"
                            />
                        </Grid>
                    </>
                )}


                <br/>
                <MDButton disabled={isDisabled} style={{marginRight: 5}} color="success" onClick={onCreate}>
                    Crear
                </MDButton>
                <MDButton onClick={handleClose} color="error">
                    Cancelar
                </MDButton>
            </MDBox>
        </Modal>
    );
}
