import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Modal, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FormField from "../../../components/Custom/FormField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function AddModal({ open, handleClose, onCreate, model, onChange, residential }) {

  let isDisabled =React.useMemo(()=>{
    return !(model.residential && model.inventory && model.reference)})

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          AGREGAR MOBILIARIO
        </Typography>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.inventory}
            name="inventory"
            onChange={onChange}
            fontWeight="bold"
            label="Tipo De Mobiliario"
            placeholder="Bomba de Agua 1000cc"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.reference}
            name="reference"
            onChange={onChange}
            fontWeight="bold"
            label="No. De Referencia"
            placeholder="B-404583"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.description}
            name="description"
            onChange={onChange}
            fontWeight="bold"
            label="Detalles"
            placeholder="Con 2 años de uso"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <Autocomplete
            value={model.residential}
            onChange={(e, value) =>
              onChange(e, { id: value.id, label: value.label, autocomplete: "residential" })
            }
            options={residential}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <FormField {...params} label="Residencial" InputLabelProps={{ shrink: true }} />
            )}
            disableClearable
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
              );
            }}
          />
        </Grid>
        <br />
        <MDButton disabled={isDisabled} style={{ marginRight: 5 }} color="success" onClick={onCreate}>
          Crear
        </MDButton>
        <MDButton onClick={handleClose} color="error">
          Cancelar
        </MDButton>
      </MDBox>
    </Modal>
  );
}
