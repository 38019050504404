import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {FormLabel, Typography} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from "react";
import {DateTime, Settings} from "luxon";
import Grid from "@mui/material/Grid";

Settings.defaultLocale = 'es-do'

var formatter = new Intl.NumberFormat('es-do', {
    style: 'currency',
    currency: 'DOP',
});


export default function ValidateModal({
                                          open,
                                          handleClose,
                                          onSuccess,
                                          incomeValue,
                                          othersIncomeValue,
                                          expensesValue,
                                          balance,
                                          filtersValues,
                                          previousDueValue,
                                          gasValue
                                      }) {


    let from = filtersValues.from ? DateTime.fromFormat(filtersValues.from, 'yyyy-MM-dd').toFormat('DDD') : '';
    let to = filtersValues.to ? DateTime.fromFormat(filtersValues.to, 'yyyy-MM-dd').toFormat('DDD') : ''
    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth={true}>
            <DialogContent>
                <MDBox   display="flex"
                         flexDirection={'column'}
                         justifyContent="center"
                         pt={4}
                         pb={4}
                         px={3}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Validación de Registro contable
                    </Typography>
                    <Typography id="modal-modal-title" variant="small" component="small">
                        del {from} al {to}
                    </Typography>
                    <br/>

                    <MDBox mr={2}>
                        <Grid item xs={12}>
                            <FormLabel style={{fontSize: 14}}>Total de ingresos brutos</FormLabel>
                            <h4 style={{color: '#000'}}>{formatter.format(incomeValue)}</h4>
                        </Grid>
                    </MDBox>
                    <br/>

                    <MDBox mr={2}>
                        <Grid item xs={12}>
                            <FormLabel style={{fontSize: 14}}>Total de deuda anterior</FormLabel>
                            <h4 style={{color: '#000'}}>{formatter.format(previousDueValue)}</h4>
                        </Grid>
                    </MDBox>
                    <br/>

                    <MDBox mr={2}>
                        <Grid item xs={12}>
                            <FormLabel style={{fontSize: 14}}>Total de gas</FormLabel>
                            <h4 style={{color: '#000'}}>{formatter.format(gasValue)}</h4>
                        </Grid>
                    </MDBox>
                    <br/>

                    <MDBox mr={2}>
                        <Grid item xs={12}>
                            <FormLabel style={{fontSize: 14}}>Otros ingresos</FormLabel>
                            <h4 style={{color: '#000'}}>{formatter.format(othersIncomeValue)}</h4>
                        </Grid>
                    </MDBox>
                    <br/>
                    <MDBox mr={2}>
                        <Grid item xs={12}>
                            <FormLabel style={{fontSize: 14}}>Total de gastos</FormLabel>
                            <h4 style={{color: '#000'}}>{formatter.format(expensesValue)}</h4>
                        </Grid>
                    </MDBox>
                    <br/>
                    <MDBox mr={2}>
                        <Grid item xs={12}>
                            <FormLabel style={{fontSize: 14}}>Total de utilidades</FormLabel>
                            <h4 style={{color: '#000'}}>{formatter.format(incomeValue + gasValue + previousDueValue + othersIncomeValue - expensesValue)}</h4>
                        </Grid>
                    </MDBox>
                    <br/>
                    <MDBox mr={2}>
                        <Grid item xs={12}>
                            <FormLabel style={{fontSize: 14}}>Balance anterior</FormLabel>
                            <h4 style={{color: '#000'}}>{formatter.format(balance)}</h4>
                        </Grid>
                    </MDBox>
                    <br/>
                    <MDBox mr={2}>
                        <Grid item xs={12}>
                            <FormLabel style={{fontSize: 14}}>Balance actual</FormLabel>
                            <h4 style={{color: '#000'}}>{formatter.format(incomeValue + previousDueValue + gasValue + othersIncomeValue + Number(balance) - expensesValue)}</h4>
                        </Grid>
                    </MDBox>
                    <br/>
                    <MDBox mr={2}>
                        <Grid item xs={12}>
                            <FormLabel style={{fontSize: 14}}>Observación</FormLabel>
                            <h4 style={{color: '#000'}}>Balance actual pasará al próximo mes como balance anterior</h4>
                        </Grid>
                    </MDBox>

                    <br/>
                    <MDButton onClick={onSuccess} color="success">
                        Confirmar
                    </MDButton>
                    <br/>
                    <MDButton onClick={handleClose} color="error">
                        Cerrar
                    </MDButton>

                </MDBox>
            </DialogContent>
        </Dialog>
    );
}
