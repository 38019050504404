import {jsPDF} from "jspdf";
import logo from "../../../assets/logo.png";
import {DateTime} from "luxon";

var formatter = new Intl.NumberFormat('es-do', {
    style: 'currency',
    currency: 'DOP',
});

function createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
        result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 65,
            align: "center",
            padding: 0
        });
    }
    return result;
}

export function PdfFile(data = [], filtersValues, incomeValue,othersIncomeValue, expensesValue, balance,setLoading, previousDueValue, gasValue) {
    const doc = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true,
        floatPrecision: 16,
        compress: true,
    });

    let from = filtersValues.from ? DateTime.fromFormat(filtersValues.from, 'yyyy-MM-dd').toFormat('DDD') : '';
    let to = filtersValues.to ? DateTime.fromFormat(filtersValues.to, 'yyyy-MM-dd').toFormat('DDD') : ''

    doc.addImage(logo, "JPEG", 85, 5, 40, 40);
    doc.text(`Registro contable: ${filtersValues.residential.label}`, 105, 55, null, null, "center");
    doc.text(`del ${from} al ${to}`, 105, 61, null, null, "center");
    let parserData = data.map(d => {
        return {
            'Fecha': d.date,
            'Detalles': d.detail ? d.detail : '---',
            'Ingresos': d.income ? formatter.format(d.income) : '---',
            'Gas': d.gas ? formatter.format(d.gas) : '---',
            'Deuda anterior': d.previousDue ? formatter.format(d.previousDue) : '---',
            'Otros Ingresos': d.othersIncome ? formatter.format(d.othersIncome) : '---',
            ' Gastos ': d.expenses ? formatter.format(d.expenses) : '---',
        }
    })

    let dataCost=[{
        id:1,
        'Ingresos':formatter.format(incomeValue),
        'Gas':formatter.format(gasValue),
        'Deuda anterior':formatter.format(previousDueValue),
        'Otros Ingresos':formatter.format(othersIncomeValue),
        'Gastos':formatter.format(expensesValue),
    }]

    let dataResult=[{
        id:2,
        'Balance anterior':formatter.format( Number(balance)),
        'Balance actual':formatter.format(incomeValue + othersIncomeValue + Number(balance) - expensesValue),
    }]

    const headersCost = createHeaders([
        "Ingresos",
        "Gas",
        "Deuda anterior",
        "Otros Ingresos",
        "Gastos",
    ]);

    const headersResult = createHeaders([
        "Balance anterior",
        "Balance actual",
    ]);

    const headers = createHeaders([
        "Fecha",
        "Detalles",
        "Ingresos",
        "Gas",
        "Deuda anterior",
        "Otros Ingresos",
        " Gastos ",
    ]);

    // let wantedTableWidth = 100;
    // let pageWidth = doc.internal.pageSize.width;
    // let margin = (pageWidth - wantedTableWidth) / 2;

    let fontSize = 9.2
    let x = 3

    doc.table(x, 70, dataCost, headersCost, {autoSize: true, fontSize, padding:6});

    doc.table(x, 110, dataResult, headersResult, {autoSize: true, fontSize, padding:6, });

    doc.table(x, 150, parserData, headers, {autoSize: true, padding:4, fontSize});

    setLoading(false)
    doc.save(`Contabilidad.pdf`);
}


