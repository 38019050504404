import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../components/Custom/FormField";

function BasicInfo({model}) {
    return (
        <Card id="basic-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
                <MDTypography variant="h5">Información de la cuenta</MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Nombres" placeholder="Alec" value={model.firstName}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Apellidos" placeholder="Thompson" value={model.lastName}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Correo" placeholder="Thompson" value={model.email}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField label="Número de teléfono" placeholder="Thompson" value={model.phoneNumber}/>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default BasicInfo;
