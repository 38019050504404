import {BUSINESS_BG_COLOR, BUSINESS_EMAIL, BUSINESS_LOGO, BUSINESS_NAME, BUSINESS_PHONE} from "../config";
import {firestore} from "../firebase";

export const paymentStatus = [
    {id: 'all', label: 'Todos'},
    {id: 'completed', label: 'Completado'},
    {id: 'pending', label: 'Pendiente'}
]

export const paymentStatusInput = [
    {id: 'completed', label: 'Completado'},
    {id: 'pending', label: 'Pendiente'}
]

export const returnLabel = (text) => {
    switch (text) {
        case 'all':
            return 'Todos'
        case 'pending':
            return 'Pendiente'
        case 'completed':
            return 'Completado'
        default:
            return text
    }
}

export const months = [
    {selected:false, label:'Enero',id:1},
    {selected:false, label:'Febrero',id:2},
    {selected:false, label:'Marzo',id:3},
    {selected:false, label:'Abril',id:4},
    {selected:false, label:'Mayo',id:5},
    {selected:false, label:'Junio',id:6},
    {selected:false, label:'Julio',id:7},
    {selected:false, label:'Agosto',id:8},
    {selected:false, label:'Septiembre',id:9},
    {selected:false, label:'Octubre',id:10},
    {selected:false, label:'Noviembre',id:11},
    {selected:false, label:'Diciembre',id:12},]


export const billingDayData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]


export const sendReceipt = async (to, amount, date) => {
    try {
        await firestore.collection("mail")
            .add({
                template: {
                    data: {
                        amountPaid: amount,
                        company: BUSINESS_NAME,
                        invoiceDate: date,
                        phone_company: BUSINESS_PHONE,
                        email_company: BUSINESS_EMAIL,
                        copyright: 'Ecorp Code',
                        logo: BUSINESS_LOGO,
                        bgColor: BUSINESS_BG_COLOR,
                    },
                    name: 'receipt_es'
                },
                to: to,
                message: {},
            })
        return true
    } catch (e) {
        return false
    }
}


export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: 'auto',
    width: 600,
    maxHeight:'90vh',
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
};
