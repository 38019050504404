import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import AddModal from "./components/AddModal";
import DeleteModal from "./components/DeleteModal";
import EditModal from "./components/EditModal";
import CAlertModal from "../../components/Custom/CAlertModal";
import LoadingModal from "../../components/Custom/Loading";
import useData from "./data";
import Filters from "../tenants/Filters";

export default function Inventory() {
    const {
        rulesList,
        residential,
        setModel,
        cAlert,
        setCAlert,
        onChange,
        onCreate,
        onUpdate,
        onDelete,
        openDelete,
        openEdit,
        open,
        setOpen,
        setOpenDelete,
        setOpenEdit,
        model,
        loading,
        filtersValues,
        filtersOnchange,
        onFilters,
    } = useData();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Filters residential={residential} values={filtersValues} onChange={filtersOnchange} onClick={onFilters} />
            <MDBox mt={4}>
                <DataTable
                    canSearch
                    onClick={() => setOpen(true)}
                    table={{
                        columns: [
                            {Header: "residencial", accessor: "residential.label"},
                            {Header: "reglas", accessor: "rules"},
                            {accessor: "actions"},
                        ],
                        rows: rulesList,
                    }}
                />
            </MDBox>
            <AddModal
                residential={residential}
                onChange={onChange}
                onCreate={onCreate}
                open={open}
                handleClose={() => setOpen(false)}
                model={model}
                setModel={setModel}
            />
            <EditModal
                residential={residential}
                onChange={onChange}
                onCreate={onUpdate}
                open={openEdit}
                handleClose={() => setOpenEdit(false)}
                model={model}
                setModel={setModel}
            />
            <DeleteModal onDelete={onDelete} open={openDelete} handleClose={() => setOpenDelete(false)}/>
            <CAlertModal alert={cAlert} close={setCAlert}/>
            <LoadingModal loading={loading}/>
        </DashboardLayout>
    );
}
