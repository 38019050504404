// eslint-disable-next-line max-classes-per-file
class AlertModel {
  constructor() {
    this.open = false;
    this.type = "info";
    this.ms = "";
  }
}

class ResidentialModel {
  constructor() {
    this.id = 0;
    this.maintenanceCost = 0;
    this.residential = "";
    this.address = "";
    this.residentialQty = "";
    this.billingDay = "";
  }
}

class ApartmentsModel {
  constructor() {
    this.id = 0;
    this.apartment = "";
    this.tenant = null;
    this.residential = null;
    this.billingDay = "";
    this.previousDueAmount = '';
    this.previousDueMonths = '';
    this.previousDueLastPayment = '';
  }
}

class TenantsModel {
  constructor() {
    this.id = null;
    this.tenant = "";
    this.firstName = "";
    this.lastName = "";
    this.password = "";
    this.userName = "";
    this.phoneNumber = "";
    this.email = '';
    this.mail = '';
    this.comment = '';
    this.residential = null;
    this.apartment = null;
  }
}

class UserModel {
  constructor() {
    this.id = null;
    this.firstName = "";
    this.lastName = "";
    this.password = "";
    this.userName = "";
    this.phoneNumber = "";
    this.email = '';
    this.mail = '';
  }
}

class BankModel {
  constructor() {
    this.id = 0;
    this.bank = "";
    this.account = "";
    this.headline ="";
    this.type ="";
  }
}

class InventoryModel {
  constructor() {
    this.id = 0;
    this.residential = null;
    this.inventory = "";
    this.reference = "";
    this.description = "";
  }
}

class BalanceModel {
  constructor() {
    this.id = 0;
    this.residential = null;
    this.balance = 0;
  }
}

class GasModel {
  constructor() {
    this.id = 0;
    this.residential = null;
    this.apartment = null;
    this.tenant = null;
    this.paymentStatus = null;
    this.gallonsQty = "";
    this.price = "";
    this.amount = "";
    this.bank = null;
  }
}

class IncomeModel {
  constructor() {
    this.id = 0;
    this.residential = null;
    this.apartment = null;
    this.tenant = null;
    this.comment = "";
    this.amount = "";
    this.bank = null;
  }
}

class ExpensesModel {
  constructor() {
    this.id = 0;
    this.residential = null;
    this.paymentStatus = null;
    this.concept = "";
    this.amount = "";
    this.bank = null;
    this.provider = null;
  }
}

class ProviderModel {
  constructor() {
    this.id = 0;
    this.provider = "";
    this.phoneNumber = "";
    this.comment = "";
  }
}

class PayrollModel {
  constructor() {
    this.id = 0;
    this.employeesName = "";
    this.employeesTel = "";
    this.employeesPosition = "";
    this.employeesSalary = "";
    this.employeesPay = "";
    this.Residential = null;
  }
}

class FiltersModel {
  constructor() {
    this.residential = {id:'', label:''};
    this.apartment = {id:'', label:''};
    this.bank = {id:'', label:''};
    this.paymentStatus = {id:'', label:''};
    this.from = null;
    this.to = null;
    this.balance = 0;

  }
}

class RulesModel {
  constructor() {
    this.id = 0;
    this.residential = null;
    this.rules = "";
  }
}

export {
  BalanceModel,
  ApartmentsModel,
  AlertModel,
  BankModel,
  ResidentialModel,
  TenantsModel,
  IncomeModel,
  ExpensesModel,
  ProviderModel,
  PayrollModel,
  GasModel,
  InventoryModel,
  FiltersModel,
  RulesModel,
  UserModel,
};
