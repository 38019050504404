import React from "react";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Snackbar from "@mui/material/Snackbar";
import { AlertModel } from "../../model";

export default function CAlertModal({ alert, close }) {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={alert.open}>
      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.open}
        onClose={() => close(new AlertModel())}
      >
        <Alert severity={alert.type}>{alert.ms}</Alert>
      </Snackbar>
    </Backdrop>
  );
}
