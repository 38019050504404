import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {Modal, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FormField from "../../../components/Custom/FormField";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
};

export default function PasswordModal({
                                          open,
                                          handleClose,
                                          onCreate,
                                          password,
                                        setPassword,
                                      }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MDBox sx={style}>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                    Actualizar contraseña
                </Typography>
                <br/>
                <Grid item xs={12}>
                    <FormField
                        value={password}
                        name="password"
                        onChange={(e)=>setPassword(e.target.value)}
                        fontWeight="bold"
                        label="Contraseña"
                        placeholder="******"
                    />
                </Grid>
                <br/>
                <MDButton style={{marginRight: 5}} color="success" onClick={onCreate}>
                    Actualizar
                </MDButton>
                <MDButton onClick={handleClose} color="error">
                    Cancelar
                </MDButton>
            </MDBox>
        </Modal>
    );
}
